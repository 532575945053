.section-pages-main-content {
    text-align: center;
    overflow-y: hidden;
    margin-top: 0;
}

.section-pages-main-content .section-title {
    font-size: var(--font-size-h4);
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
}

.section-pages-main-content .description {
    max-width: 545px;
    margin: 0 auto;
    text-align: left;
    font-size: var(--font-size-body1);
}

.section-pages-main-content .description p {
    margin: 0;
}

.section-pages-main-content .description-title {
    margin: 45px 0 15px;
    font-size: var(--font-size-body1);
    font-weight: 700;
    text-align: center;
}

.section-pages-main-content .ui-box-video {
    position: relative;
    max-width: 984px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
}

/* BP 3+ */
@media only screen and (min-width: 768px) {
    .section-pages-main-content {
        margin-bottom: 60px;
        margin-top: 90px;
    }

    .section-pages-main-content .ui-box-video {
        margin-top: 45px;
    }

    .section-pages-main-content .section-title {
        margin-bottom: 30px;
        font-size: var(--font-size-heading1);
        line-height: 1;
    }
}

/* BOS module video mobile fullscreen */
@media screen and (max-width: 767px) {
    .section-pages-main-content.ui-bos-video-section {
        width: 100%;
    }

    .section-pages-main-content.ui-bos-video-section .description {
        padding: 0 15px;
    }
}
